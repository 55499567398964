import React from "react";

export const Services = (props) => {
  return (
    <section id="services" className="services-section">
      <div className="container">
        <div className="section-title">
          <h2>Our Services</h2>
        </div>
        <div className="services-grid">
          {props.data ? (
            props.data.map((d, i) => (
              <div key={`${d.name}-${i}`} className="service-card">
                <img
                  src={d.icon}
                  alt={d.name}
                  className="service-icon"
                />
                <div className="service-content">
                  <h3>{d.name}</h3>
                  <p dangerouslySetInnerHTML={{ __html: d.text }}></p>
                </div>
              </div>
            ))
          ) : (
            <p className="loading-text">Loading...</p>
          )}
        </div>
      </div>
    </section>
  );
};