import React from "react";

export const Opst = (props) => {
  return (
    <div id="solution">
      <div className="container">
        <div className="row">
          <div className="col-xs-9 col-md-5">
            <div className="about-text">
              <h2>The OP-ST Solution</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              
              <div>
                <button className="btn btn-custom btn-lg" >
                  Learn More
                </button>

              </div>
            </div>
          </div>
          <div className="col-xs-14 col-md-7">
            {" "}
            <img
              src="img/Expert Rack Inspection.png"
              className="img-responsive"
              alt="Solution"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
