import React from 'react';
import { ReactComponent as AirCanada } from '../svg/air canada.svg';
import { ReactComponent as Bell } from '../svg/bell textron.svg';
import { ReactComponent as BestBuy } from '../svg/best buy.svg';
import { ReactComponent as Bixi } from '../svg/bixi.svg';
import { ReactComponent as Bosch } from '../svg/bosch.svg';
import { ReactComponent as CanadianTire } from '../svg/canadian tire.svg';
import { ReactComponent as CardinalHealth } from '../svg/cardinal health.svg';
import { ReactComponent as Costco } from '../svg/costco.svg';
import { ReactComponent as CoucheTard } from '../svg/couche tard.svg';
import { ReactComponent as VilledeMtl } from '../svg/ville de montreal.svg';
import { ReactComponent as FritoLay } from '../svg/frito lay.svg';
import { ReactComponent as GeneralDynamics } from '../svg/general dynamics.svg';
import { ReactComponent as GroupeRobert } from '../svg/groupe robert.svg';
import { ReactComponent as Hershey } from '../svg/hershey.svg';
import { ReactComponent as Ikea } from '../svg/ikea.svg';
import { ReactComponent as JeanCoutu } from '../svg/jean coutu.svg';
import { ReactComponent as Kraft } from '../svg/kraft.svg';
import { ReactComponent as Kroger } from '../svg/kroger.svg';
import { ReactComponent as LotoQuebec } from '../svg/loto quebec.svg';
import { ReactComponent as Lululemon } from '../svg/lululemon.svg';
import { ReactComponent as MatrinBrower } from '../svg/martin brower.svg';
import { ReactComponent as Maxi } from '../svg/maxi.svg';
import { ReactComponent as McKesson } from '../svg/mckesson.svg';
import { ReactComponent as Saputo } from '../svg/saputo.svg';
import { ReactComponent as SSense } from '../svg/ssense.svg';
import { ReactComponent as Sysco } from '../svg/sysco.svg';
import { ReactComponent as Videotron } from '../svg/videotron.svg';
import { ReactComponent as Wesco } from '../svg/wesco.svg';







export const InfiniteScrollBanner = () => {
  return (
    <>
      <div id="banner">
        <h1>Trusted by the Best</h1>
        <h2>We are proud to count among our customers global leaders who trust us to optimize the efficiency of their operations and enhance the safety and performance of their racking systems through our innovative engineering solutions.</h2>
        <article className="wrapper">
          <div className="marquee">
            <div className="marquee__group">
              <AirCanada className="logo" aria-label="Air Canada" />
              <Bell className="logo" aria-label="Bell" />
              <BestBuy className="logo" aria-label="Best Buy" />
              <Bixi className="logo" aria-label="Bixi" />
              <Bosch className="logo" aria-label="Bosch" />
              <CanadianTire className="logo" aria-label="Canadian Tire" />
              <CardinalHealth className="logo" aria-label="Cardinal Health" />
              <Costco className="logo" aria-label="Costco" />
              <CoucheTard className="logo" aria-label="Couche Tard" />
              <VilledeMtl className="logo" aria-label="Ville de Montreal" />
              <FritoLay className="logo" aria-label="Frito Lay" />
              <GeneralDynamics className="logo" aria-label="General Dynamics" />
              <GroupeRobert className="logo" aria-label="Groupe Robert" />
              <Hershey className="logo" aria-label="Hershey" />
              <Ikea className="logo" aria-label="Ikea" />
                
            </div>

            <div aria-hidden="true" className="marquee__group">
              <AirCanada className="logo" aria-label="Air Canada" />
              <Bell className="logo" aria-label="Bell" />
              <BestBuy className="logo" aria-label="Best Buy" />
              <Bixi className="logo" aria-label="Bixi" />
              <Bosch className="logo" aria-label="Bosch" />
              <CanadianTire className="logo" aria-label="Canadian Tire" />
              <CardinalHealth className="logo" aria-label="Cardinal Health" />
              <Costco className="logo" aria-label="Costco" />
              <CoucheTard className="logo" aria-label="Couche Tard" />
              <VilledeMtl className="logo" aria-label="Ville de Montreal" />
              <FritoLay className="logo" aria-label="Frito Lay" />
              <GeneralDynamics className="logo" aria-label="General Dynamics" />
              <GroupeRobert className="logo" aria-label="Groupe Robert" />
              <Hershey className="logo" aria-label="Hershey" />
              <Ikea className="logo" aria-label="Ikea" />
            </div>
          </div>

          <div className="marquee marquee--reverse">
            <div className="marquee__group">
              <JeanCoutu className="logo" aria-label="Jean Coutu" />
              <Kraft className="logo" aria-label="Kraft" />
              <Kroger className="logo" aria-label="Kroger" />
              <LotoQuebec className="logo" aria-label="Loto Quebec" />
              <Lululemon className="logo" aria-label="Lululemon" />
              <MatrinBrower className="logo" aria-label="Martin Brower" />
              <Maxi className="logo" aria-label="Maxi" />
              <McKesson className="logo" aria-label="McKesson" />
              <Saputo className="logo" aria-label="Saputo" />
              <SSense className="logo" aria-label="SSense" />
              <Sysco className="logo" aria-label="Sysco" />
              <Videotron className="logo" aria-label="Videotron" />
              <Wesco className="logo" aria-label="Wesco" />
            </div>

            <div aria-hidden="true" className="marquee__group">
              <JeanCoutu className="logo" aria-label="Jean Coutu" />
              <Kraft className="logo" aria-label="Kraft" />
              <Kroger className="logo" aria-label="Kroger" />
              <LotoQuebec className="logo" aria-label="Loto Quebec" />
              <Lululemon className="logo" aria-label="Lululemon" />
              <MatrinBrower className="logo" aria-label="Martin Brower" />
              <Maxi className="logo" aria-label="Maxi" />
              <McKesson className="logo" aria-label="McKesson" />
              <Saputo className="logo" aria-label="Saputo" />
              <SSense className="logo" aria-label="SSense" />
              <Sysco className="logo" aria-label="Sysco" />
              <Videotron className="logo" aria-label="Videotron" />
              <Wesco className="logo" aria-label="Wesco" />
            </div>
          </div>
        </article>
      </div>
    </>
  );
};